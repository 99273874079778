import React from "react";
import SectionText from "../Reusables/SectionText";
import SectionTitle from "../Reusables/SectionTitle";
import SectionWrapper from "../Reusables/SectionWrapper";

export default function AboutMe() {
  return (
    <SectionWrapper id="AboutMe">
      <SectionTitle>About me</SectionTitle>
      <SectionText>
        I'm a website and mobile developer with a passion for creating
        innovative digital solutions. With several years of experience in the
        industry, I have had the opportunity to work with a wide range of
        clients.
      </SectionText>
      <SectionText>
        I believe in clear communication and collaboration throughout the
        development process, ensuring that the end product is exactly what you
        have envisioned.
      </SectionText>
    </SectionWrapper>
  );
}

// FULL TEXT VERSION

// I'm a website and mobile developer with a passion for creating innovative digital solutions. With several years of experience in the industry, I have had the opportunity to work with a wide range of clients, from small startups to large corporations.

// My expertise includes developing custom websites and mobile apps from scratch, as well as enhancing and maintaining existing ones. I am well-versed in a variety of programming languages and technologies, including HTML, CSS, JavaScript, React, and React Native, among others.

// I take pride in delivering high-quality work that meets the unique needs of my clients. I believe in clear communication and collaboration throughout the development process, ensuring that the end product is exactly what you envisioned.

// If you're looking for a reliable and skilled website and mobile developer, look no further. Let's work together to bring your digital vision to life.
