import React from "react";
import SectionWrapper from "../Reusables/SectionWrapper";
import SectionTitle from "../Reusables/SectionTitle";
import SectionComponent from "../Reusables/SectionComponent";

export default function Education() {
  return (
    <SectionWrapper id="Education">
      <SectionTitle>Education</SectionTitle>
      <SectionComponent
        date="June 2022"
        title="React - The Complete Guide (Hooks, React Router, Redux)"
        subtitle1="Udemy - Maximilian Schwarzmüller"
        subtitle2="Online, 48 hours of video lessons, 494 total Lessons."
      ></SectionComponent>
      <SectionComponent
        date="March 2022"
        title="Build Responsive Real-World Websites with HTML and CSS"
        subtitle1="Udemy - Jonas Schmedtmann"
        subtitle2="Online, 37 hours of video lessons, 151 total Lessons."
      ></SectionComponent>
      <SectionComponent
        date="2021 - 2022"
        title="The Complete JavaScript Course"
        subtitle1="Udemy - Jonas Schmedtmann"
        subtitle2="Online, 69 hours of video lessons, 320 total Lessons."
      ></SectionComponent>
      <SectionComponent
        date="2015 - 2020"
        title="Bachelor’s Degree"
        subtitle1="Pontifical Catholic University of Rio de Janeiro"
        subtitle2={`Product Design, Bachelor’s Degree - RJ, Brazil.`}
      ></SectionComponent>
    </SectionWrapper>
  );
}
