import React from "react";
import styled from "styled-components";
import Device from "../../Defaults/Device";

import SeaBackground from "../../Images/SeaBackground.jpg";
import SeaBackgroundVertical from "../../Images/SeaBackgroundVertical.jpg";

export default function BackgroundFooter() {
  return <Wrapper></Wrapper>;
}

const Wrapper = styled.div`
  position: absolute;
  bottom: -4rem;
  left: -3rem;
  z-index: -1;
  transform: rotate(4deg) scaleY(-1);
  width: 24rem;
  height: 110vh;
  background-image: url(${SeaBackgroundVertical});
  background-size: cover;

  @media ${Device.laptop} {
    width: 115vw;
    height: 24vh;

    background-image: url(${SeaBackground});
    background-position: center;
  }
`;
