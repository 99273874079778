import React from "react";
import styled from "styled-components";
import Info from "../../Icons/NavIcons/Info";
import Mobile from "../../Icons/NavIcons/Mobile";
import Book from "../../Icons/NavIcons/Book";
import Briefcase from "../../Icons/NavIcons/Briefcase";
import Star from "../../Icons/NavIcons/Star";
import Share from "../../Icons/NavIcons/Share";
import Languages from "../../Icons/NavIcons/Languages";
import Download from "../../Icons/NavIcons/Download";
import { Color } from "../../Defaults/Color";
import Device from "../../Defaults/Device";
import { useDispatch } from "react-redux";
import modalSlice from "../../Redux/modalSlice";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

export default function Nav({ twoColumns }) {
  const dispatch = useDispatch();

  function navigate(link) {
    const element = document.getElementById(link);
    let offset;

    if (link === "AboutMe" || link === "Languages") {
      offset = -220;
    } else {
      offset = -150;
    }
    const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }

  const onDownload = () => {
    // using Java Script method to get PDF file
    fetch(`Ulysses Pinheiro - Web Developer.pdf`).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Ulysses Pinheiro - WebDeveloper.pdf";
        alink.click();
      });
    });
  };

  const navPart1 = (
    <>
      <NavItem onClick={() => navigate("AboutMe")}>
        <div>{<Info className="icon" />}</div>
        <p>About me</p>
      </NavItem>
      <NavItem onClick={() => navigate("PreviousExperience")}>
        <div>{<Briefcase className="icon" />}</div>
        <p>Work Experience</p>
      </NavItem>
      <NavItem onClick={() => navigate("Education")}>
        <div>{<Book className="icon" />}</div>
        <p>Education</p>
      </NavItem>
      <NavItem onClick={() => navigate("Projects")}>
        <div>{<Mobile className="icon" />}</div>
        <p>Projects</p>
      </NavItem>
    </>
  );
  const navPart2 = (
    <>
      <NavItem onClick={() => navigate("Skills")}>
        <div>{<Star className="icon" />}</div>
        <p>Skills</p>
      </NavItem>
      <NavItem onClick={() => navigate("Languages")}>
        <div>{<Languages className="icon" />}</div>
        <p>Languages</p>
      </NavItem>
      <NavItem
        onClick={() => {
          dispatch(modalSlice.actions.showModal());
        }}
      >
        <div>{<Share className="icon" />}</div>
        <p>Share</p>
      </NavItem>
      <NavItem onClick={() => onDownload()}>
        <div>{<Download className="icon" />}</div>
        <p>Download</p>
      </NavItem>
    </>
  );

  let navItems;

  if (twoColumns) {
    navItems = (
      <>
        <div className="gridChild">{navPart1}</div>
        <div className="gridChild">{navPart2}</div>
      </>
    );
  } else {
    navItems = (
      <>
        {navPart1}
        {navPart2}
      </>
    );
  }

  return (
    <GridContainer className={twoColumns ? "twoColumns" : ""}>
      {navItems}
    </GridContainer>
  );
}
const GridContainer = styled.div`
  display: grid;
  gap: 0.3rem;
  color: ${Color.white};

  &.twoColumns {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    @media ${Device.mobile} {
      gap: 0.3rem;
    }
  }

  .gridChild {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  cursor: pointer;
  padding: 0.3rem;
  max-width: 15rem;
  font-size: 0.95rem;

  div {
    fill: white;
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: ${Color.hover};
    border-radius: 0.3rem;
  }

  @media ${Device.mobile} {
    font-size: 0.95rem;
    gap: 0.5rem;
  }

  .icon {
    height: 1.3rem;

    @media ${Device.mobile} {
      height: 1.2rem;
    }
  }
`;
