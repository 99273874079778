import React from "react";
import styled from "styled-components";

import Website from "../../Icons/MainIcons/Website";
import Github from "../../Icons/MainIcons/Github";
import LinkedIn from "../../Icons/MainIcons/LinkedIn";
import Gmail from "../../Icons/MainIcons/Gmail";
import Whatsapp from "../../Icons/MainIcons/Whatsapp";

const Links = [
  "https://www.linkedin.com/in/ulysses-pinheiro-8308b1171/?locale=en_US",
  "https://github.com/UlyssesPinheiro",
  "https://ulyssespinheiro.com/",
  "mailto:ulyssespinheiro00@gmail.com",
  "https://api.whatsapp.com/send/?phone=5521979211446&text&type=phone_number&app_absent=0",
];

export default function SocialIcons() {
  function openLink(link) {
    window.open(link);
  }

  return (
    <ContainerDiv>
      <div onClick={openLink.bind(this, Links[0])}>
        <LinkedIn height={"1.5rem"} fill={"#ffffff"}></LinkedIn>
      </div>
      <div onClick={openLink.bind(this, Links[1])}>
        <Github height={"1.5rem"} fill={"#ffffff"}></Github>
      </div>
      <div onClick={openLink.bind(this, Links[2])}>
        <Website height={"1.5rem"} fill={"#ffffff"}></Website>
      </div>
      <div onClick={openLink.bind(this, Links[3])}>
        <Gmail height={"1.4rem"} fill={"#ffffff"}></Gmail>
      </div>
      <div onClick={openLink.bind(this, Links[4])}>
        <Whatsapp height={"1.6rem"} fill={"#ffffff"}></Whatsapp>
      </div>
    </ContainerDiv>
  );
}

const ContainerDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  div {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ffffff37;
      border-radius: 20rem;
    }
  }
`;
