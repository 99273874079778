import React from "react";
import styled from "styled-components";
import { Fade as Hamburger } from "hamburger-react";

export default function BurguerMenu({
  toggleMenu,
  menuExpanded,
  screenScrolled,
}) {
  return (
    <Wrapper>
      <Hamburger
        size={40}
        toggled={menuExpanded}
        toggle={() => toggleMenu()}
        direction="right"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  transition: all 300ms;
  display: flex;
  align-items: center;
`;
