import React from "react";
import styled from "styled-components";
import { Color } from "../../../Defaults/Color";

export default function ExpandedMenuBackground() {
  return <Wrapper></Wrapper>;
}

const Wrapper = styled.div`
  position: absolute;
  top: -3rem;
  left: -3rem;
  z-index: -1;
  transform: rotate(-4deg);
  width: 120vw;
  height: 20rem;
  background-color: ${Color.main900};
`;
