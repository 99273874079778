import React from "react";
import styled from "styled-components";
import Profile from "./Profile";
import SocialIcons from "./SocialIcons";
import Nav from "./Nav";
import Background from "./Background";
import Device from "../../Defaults/Device";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import BurguerMenu from "./BurguerMenu";
import { useState } from "react";
import ExpandedMenu from "./ExpandedMenu/ExpandedMenu";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import { useEffect } from "react";

export default function Header() {
  const { height, width } = useWindowDimensions();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [screenScrolled, setScreenScrolled] = useState(false);

  const { scrollYProgress } = useScroll();

  function toggleMenu() {
    setMenuExpanded((prev) => !prev);
  }

  function compactNavbar() {
    if (window.scrollY > 100) {
      setScreenScrolled(true);
    } else {
      setScreenScrolled(false);
    }
  }

  useEffect(() => {
    return scrollYProgress.onChange(() => {
      if (scrollYProgress.prev > 0.97) return;

      setMenuExpanded(false);
    });
  }, [scrollYProgress, height]);

  useEffect(() => {
    window.addEventListener("scroll", compactNavbar);

    return () => {
      window.removeEventListener("scroll", compactNavbar);
    };
  }, []);

  return (
    <Wrapper>
      <div
        className={`fixed ${
          screenScrolled && width < Device.laptopNum ? "screenScrolled" : ""
        }`}
      >
        <Profile
          screenScrolled={screenScrolled && width < Device.laptopNum}
        ></Profile>
        {width > Device.laptopNum ? (
          <>
            <Nav></Nav>
            <SocialIcons></SocialIcons>
          </>
        ) : (
          <BurguerMenu
            toggleMenu={toggleMenu}
            menuExpanded={menuExpanded}
          ></BurguerMenu>
        )}
        <Background screenScrolled={screenScrolled}></Background>
      </div>
      {width < Device.laptopNum ? (
        <AnimatePresence>
          {menuExpanded ? (
            <motion.div
              initial={{ translateY: -360 }}
              animate={{ translateY: 0, position: "fixed", width: "100%" }}
              exit={{ translateY: -360 }}
              transition={{ ease: "easeOut", duration: 0.3 }}
            >
              <ExpandedMenu screenScrolled={screenScrolled} />
            </motion.div>
          ) : (
            ""
          )}
        </AnimatePresence>
      ) : (
        ""
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 23rem;
  position: relative;
  z-index: 10;

  @media ${Device.laptop} {
    height: 10rem;
    width: 100%;
    grid-column: span 2;
  }

  .fixed {
    z-index: 10;
    height: 100vh;
    position: fixed;
    padding: 3rem;
    padding-top: 5%;
    padding-right: 10rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-family: "lato", sans-serif;
    }

    @media ${Device.laptop} {
      padding: 1.7rem 2.5rem;
      position: fixed;
      flex-direction: row;
      width: 100vw;
      height: 10rem;
      transition: all 300ms;

      &.screenScrolled {
        padding: 1rem 2.5rem;
        height: 6rem;
      }
    }

    @media ${Device.mobile} {
      padding: 1.3rem;
      height: 8rem;

      &.screenScrolled {
        padding: 1rem;
        align-items: center;
      }
    }
  }
`;
