import React from "react";
import SectionTitle from "../Reusables/SectionTitle";
import SectionWrapper from "../Reusables/SectionWrapper";
import SectionComponent from "../Reusables/SectionComponent";

export default function DevelopedProjects() {
  return (
    <SectionWrapper id="Projects">
      <SectionTitle>Projects</SectionTitle>
      <SectionComponent
        date="April 2022"
        title="Incomefy - App for selling products"
        subtitle1="React Native / Expo - Multiplatform App"
        subtitle2="Personal Project"
        link="https://incomefy.netlify.app/"
      >
        The app allows the user to publish products and services, search them
        and reorder product listings. Local state storage managed with Redux
        Persist. Layout CSS developed with Styled-Components.
      </SectionComponent>
      <SectionComponent
        date="February 2022"
        title="My Habits - Create new habits"
        subtitle1="Responsive Website - mobile/tablet/desktop"
        subtitle2="Personal Project"
        link="https://ulyssespinheiro.github.io/my-habits/"
      >
        Using JavaScript to add HTML Markups. Use of DOM manipulation to know
        how many objects to generate and display on screen. To create new
        habits, the user fills a form. Data is stored locally, and it is not
        lost after a page reload.
      </SectionComponent>
      <SectionComponent
        date="January 2022"
        title="Forkify"
        subtitle1="Cooking Recipes Website [API]"
        subtitle2="Course Project (The Complete JavaScript Course 2022)"
        link="https://forkify-ulysses.netlify.app/"
      >
        JavaScript code structure built using MVC method. Use of Ajax calls to
        receive data from an API. The HTML and CSS were pre-built by the course.
        The focus of this project was to expand the knowledge of JavaScript .
      </SectionComponent>
      <SectionComponent
        date="June 2021"
        title="Memory Game"
        subtitle1="Card Memory Game"
        subtitle2="Personal Project"
        link="https://ulyssespinheiro.github.io/Memory-Game/"
      >
        Learned how to simulate the flip of a card, using classes with different
        widths and changing the image SRC attribute, applying transition
        durations and sounds. Discovered how to generate a random array of
        cards, and detect the order of cards on the screen to create the game
        logic.
      </SectionComponent>
    </SectionWrapper>
  );
}
