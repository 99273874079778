const Device = {
  laptop: "(max-width: 1025px)",
  tablet: "(max-width: 769px)",
  mobile: "(max-width: 481px)",

  laptopNum: 1025,
  tabletNum: 769,
  mobileNum: 481,
};

export default Device;
