import React from "react";
import styled from "styled-components";
import SocialIcons from "../SocialIcons";
import ExpandedMenuBackground from "./ExpandedMenuBackground";
import Nav from "../Nav";
import Device from "../../../Defaults/Device";

export default function ExpandedMenu({ screenScrolled }) {
  return (
    <Wrapper className={screenScrolled ? "screenScrolled" : ""}>
      <Nav twoColumns={true}></Nav>
      <SocialIcons></SocialIcons>
      <ExpandedMenuBackground></ExpandedMenuBackground>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  margin-top: 10rem;
  padding: 2rem;

  width: 100%;
  height: 15rem;

  display: grid;
  gap: 1.5rem;

  &.screenScrolled {
    margin-top: 5.5rem;
  }

  @media ${Device.mobile} {
    padding: 1rem;
  }
`;
