import React from "react";
import styled from "styled-components";
import Device from "../../Defaults/Device";
import { Color } from "../../Defaults/Color";

export default function Name() {
  return (
    <Wrapper>
      <div className="name">
        <h2>
          Ulysses <br /> Pinheiro
        </h2>
        <p>Front End Web Developer</p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: ${Color.white};
  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-family: "Unna", serif;
      font-weight: bold;
      font-size: 3rem;
      @media ${Device.laptop} {
        font-size: 2rem;
      }
    }

    font-size: 0.8rem;
  }
  @media ${Device.mobile} {
    display: none;
  }
`;
