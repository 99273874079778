import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.03 65.8" {...props}>
    <path d="M38.31 52.37c0 1.77.05 3.25-.01 4.73-.08 1.76.28 3.34 1.63 4.6-8.36 2.94-23.1 1.53-32.78-10.23-9.73-11.81-9.5-28.87.59-40.65C17.53-.6 34.53-3.39 47.54 4.37c13.75 8.21 17.76 23.77 14.28 35.41-1.3-1.1-2.56-2.12-3.75-3.22-.19-.18-.18-.68-.13-1.01.62-3.63.31-7.21-.51-10.78-.22-.93-.64-1.19-1.57-1.17-2.96.06-5.92.02-9.02.02l.3 3.69c-1.47-1.24-2.9-2.16-4.84-1.86l-.35-1.77H21.04c-.71 5.22-.7 10.38 0 15.66h17.2v4.72h-16.4c.41 1.42.73 2.64 1.13 3.84.85 2.58 1.96 5.04 3.63 7.22 3.15 4.13 6.57 4.16 9.77.06.62-.82 1.17-1.7 1.94-2.81zM21.9 18.83h19.23c-.3-1.08-.54-2.07-.85-3.03-1.09-3.36-2.41-6.6-4.97-9.15-2.54-2.53-5.15-2.53-7.65.04-3.24 3.35-4.59 7.62-5.76 12.14zm-5.97 4.81c-3.11 0-6.29-.02-9.47.03-.25 0-.62.45-.71.75-1.34 4.72-1.31 9.45-.04 14.19.17.63.47.79 1.07.78 1.79-.03 3.59-.01 5.38-.01h3.76c.01-5.3.01-10.44.01-15.74zm5.63-16.77c-4.43.95-12.6 8.31-13.37 12.03 2.76 0 5.5.02 8.24-.03.25 0 .67-.34.73-.58.91-4 2.27-7.82 4.4-11.42zm19.73-.23c2.28 3.76 3.62 7.53 4.49 11.46.14.64.43.82 1.04.81 2.27-.03 4.53-.01 6.8-.01h1.45c-3.23-5.71-7.65-9.69-13.78-12.26zM7.92 44.12c3.23 5.73 7.67 9.68 13.77 12.24-2.28-3.82-3.66-7.67-4.57-11.7-.05-.21-.37-.51-.57-.51-2.8-.04-5.6-.03-8.63-.03z" />
    <path d="M49.17 53.34c-1.28 1.53-2.49 2.98-3.71 4.43-.4.48-.72 1.08-1.23 1.39-.5.3-1.27.54-1.78.37-.46-.15-.74-.87-1.05-1.37-.1-.16-.02-.43-.02-.65 0-8.9.01-17.79-.01-26.69 0-.9.1-1.73 1-2.13.89-.4 1.59.07 2.27.65 6.75 5.73 13.51 11.46 20.27 17.17.74.62 1.4 1.26.98 2.32-.41 1.05-1.35 1.08-2.3 1.08-2.3-.01-4.61 0-7.05 0 .15.4.25.69.37.97l4.17 9.18c1.09 2.41.49 3.96-1.92 5.04-.27.12-.53.24-.8.35-1.95.79-3.56.2-4.45-1.72-1.42-3.05-2.78-6.12-4.17-9.17-.15-.36-.33-.72-.57-1.22z" />
  </svg>
);

export default SvgComponent;
