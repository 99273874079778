import React from "react";
import styled from "styled-components";
import Background from "../../Images/SeaBackground.jpg";
import { Color } from "../../Defaults/Color";
import { Font } from "../../Defaults/Font";
import ShareOption from "./ShareOption";
import LinkField from "./LinkField";
import Whatsapp from "../../Icons/MainIcons/Whatsapp";
import Telegram from "../../Icons/MainIcons/Telegram";
import LinkedIn from "../../Icons/MainIcons/LinkedIn";
import Email from "../../Icons/MainIcons/Email";
import XCloseModal from "../../Icons/MainIcons/XCloseModal";
import { useDispatch } from "react-redux";
import modalSlice from "../../Redux/modalSlice";
import { TelegramShareButton } from "react-share";
import SectionTitle from "../Reusables/SectionTitle";

export default function Share() {
  const dispatch = useDispatch();

  const url = window.location.href;

  return (
    <Wrapper>
      <TitleDiv>
        <SectionTitle>Share</SectionTitle>
        <CloseModal onClick={() => dispatch(modalSlice.actions.hideModal())}>
          <XCloseModal className="x-button" />
        </CloseModal>
      </TitleDiv>
      <OptionsField>
        <ShareOption
          name="Whatsapp"
          color={"#25D366"}
          href={"whatsapp://send?text=" + url}
          data-action="share/whatsapp/share"
        >
          <Whatsapp className="icon" />
        </ShareOption>

        <TelegramShareButton url={url}>
          <ShareOption name="Telegram" color={"#2BB4E4"}>
            <Telegram className="icon" />
          </ShareOption>
        </TelegramShareButton>

        <ShareOption
          name="LinkedIn"
          href={"https://www.linkedin.com/sharing/share-offsite/?url=" + url}
          target="_blank"
          color={`#0060df`}
        >
          <LinkedIn className="icon" />
        </ShareOption>

        <ShareOption
          name="Email"
          target="_blank" //does not work with mail to
          href={"mailto:?body=Check out this nice resume: " + url}
          color={"#000000"}
        >
          <Email className="icon" />
        </ShareOption>
      </OptionsField>
      <LinkField></LinkField>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: absolute;
  /* background-image: url(${Background}); */
  /* background-position: center; */
  /* background-size: cover; */
  background-color: ${Color.white};
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  color: ${Color.grey600};
  padding: 1rem 1.5rem;
  box-shadow: 0 0 4rem #00000073;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: fixed;

  .icon {
    fill: ${Color.white};
  }

  .x-button {
    fill: ${Color.main600};
  }
`;

const TitleDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    text-align: center;
    width: 100%;
    font-size: 2rem;
    ${Font.title};
    user-select: none;
    color: ${Color.main600};
  }
`;

const CloseModal = styled.div`
  position: absolute;
  right: 0;
  height: 1.5rem;
  width: 1.5rem;
`;

const OptionsField = styled.div`
  display: flex;
  gap: 0.3rem;
`;
