import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Color } from "../../Defaults/Color";
import Copy from "../../Icons/MainIcons/Copy";

export default function LinkField() {
  const [copySuccess, setCopySuccess] = useState("Copy");

  return (
    <Wrapper>
      {window.location.href}
      <CopyIcon
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setCopySuccess(" Copied!");
        }}
      >
        <Copy height={23} fill={Color.main600} />
        {copySuccess}
      </CopyIcon>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${Color.main200};
  padding: 0.3rem;
  border-radius: 0.4rem;
  color: ${Color.main600};

  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CopyIcon = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.3rem;
  border-radius: 0.4rem;
  user-select: none;

  &:hover {
    background-color: ${Color.main300};
  }
`;
