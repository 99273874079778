import React from "react";
import AboutMe from "./AboutMe";
import styled from "styled-components";
import { Color } from "../../Defaults/Color";
import DevelopedProjects from "./DevelopedProjects";
import Device from "../../Defaults/Device";
import Education from "./Education";
import PreviousExperience from "./PreviousExperience";
import Skills from "./Skills";
import Languages from "./Languages";

export default function MainContent() {
  return (
    <Wrapper>
      <AboutMe></AboutMe>
      <hr />
      <PreviousExperience></PreviousExperience>
      <hr />
      <Education></Education>
      <hr />
      <DevelopedProjects></DevelopedProjects>
      <hr />
      <Skills></Skills>
      <hr />
      <Languages></Languages>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  max-width: 1200px;
  align-self: center;
  position: relative;

  hr {
    grid-column: span 2;
    border: 1px solid ${Color.main200};
  }

  @media ${Device.laptop} {
    grid-column: span 2;
  }
`;
