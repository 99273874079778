import React from "react";
import styled from "styled-components";
import Device from "../../Defaults/Device";

import SeaBackground from "../../Images/SeaBackground.jpg";
import SeaBackgroundVertical from "../../Images/SeaBackgroundVertical.jpg";

export default function Background({ screenScrolled }) {
  return <Wrapper className={screenScrolled ? "screenScrolled" : ""}></Wrapper>;
}

const Wrapper = styled.div`
  position: absolute;
  top: -3rem;
  left: -3rem;
  z-index: -1;
  transform: rotate(-4deg);
  width: 24rem;
  height: 110vh;
  background-image: url(${SeaBackgroundVertical});
  background-size: cover;

  @media ${Device.laptop} {
    width: 115vw;
    height: 13rem;

    background-image: url(${SeaBackground});
    background-position: center;

    transition: all 300ms;
    &.screenScrolled {
      transform: rotate(0deg);
      height: 9rem;
    }
  }

  @media ${Device.mobile} {
    height: 11.5rem;
  }
`;
