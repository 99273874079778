import React from "react";
import SectionWrapper from "../Reusables/SectionWrapper";
import SectionTitle from "../Reusables/SectionTitle";
import SectionSkills from "../Reusables/SectionSkills";
import styled from "styled-components";

const programmingSkills = [
  { skill: "React", level: "Advanced" },
  { skill: "React Native", level: "Advanced" },
  { skill: "JavaScript", level: "Advanced" },
  { skill: "HTML", level: "Advanced" },
  { skill: "CSS", level: "Advanced" },
  { skill: "Wordpress", level: "Advanced" },
  { skill: "Elementor", level: "Advanced" },
  { skill: "TypeScript", level: "Intermediate" },
  { skill: "Node & Express", level: "Intermediate" },
  { skill: "SQL & MongoDB", level: "Intermediate" },
  { skill: "Git", level: "Intermediate" },
  { skill: "Elixir & Phoenix", level: "Intermediate" },
];
const designSkills = [
  { skill: "Adobe XD & Figma", level: "Advanced" },
  { skill: "Adobe Photoshop", level: "Advanced" },
  { skill: "Adobe Illustrator", level: "Advanced" },
  { skill: "Adobe Premiere", level: "Advanced" },
];

export default function Skills() {
  return (
    <SectionWrapper id="Skills" oneCol={true}>
      <SectionTitle>Programming Skills</SectionTitle>
      <SectionSkills skills={programmingSkills}></SectionSkills>
      <Spacer></Spacer>
      <SectionTitle>Design Skills</SectionTitle>
      <SectionSkills skills={designSkills}></SectionSkills>
      <Spacer></Spacer>
    </SectionWrapper>
  );
}

const Spacer = styled.div`
  height: 2rem;
  grid-column: span 2;
`;
