import React from "react";
import styled from "styled-components";
import { Color } from "../../Defaults/Color";
import { Font } from "../../Defaults/Font";
import SectionText from "./SectionText";

export default function SectionComponent({
  date,
  title,
  subtitle1,
  subtitle2,
  children,
  link,
}) {
  return (
    <Wrapper>
      <div className="titleDiv">
        <SectionText className="date">{date}</SectionText>
        <h3 className="title">{title}</h3>
        <h4 className="subtitle1">{subtitle1}</h4>
        <SectionText>{subtitle2}</SectionText>
      </div>
      {children ? (
        <SectionText>
          {children}
          <br />
          <a href={link} target="_blank" rel="noreferrer">
            {link}
          </a>
        </SectionText>
      ) : (
        ""
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  .titleDiv {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  .title {
    ${Font.title};
    color: ${Color.black};
    font-size: 1.5rem;
  }

  .subtitle1 {
    color: ${Color.main600};
    font-weight: 600;
  }

  a {
    color: ${Color.main600};
    text-decoration: none;
    width: fit-content;
    font-size: 0.9rem;
  }

  a:hover {
    text-decoration: underline;
  }
`;
