import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import modalSlice from "../../Redux/modalSlice";

export default function ModalBackground() {
  const dispatch = useDispatch();

  return (
    <Wrapper
      onClick={() => {
        dispatch(modalSlice.actions.hideModal());
      }}
    ></Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #00000045;
  width: 100%;
  height: 100%;
  z-index: 200;
  position: fixed;
`;
