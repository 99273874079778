import React from "react";
import styled from "styled-components";
import Device from "../../Defaults/Device";

export default function SectionWrapper({ children, id, oneCol }) {
  return (
    <Wrapper className={oneCol ? "oneCol" : ""} id={id}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  column-gap: 3rem;

  &.oneCol {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  @media ${Device.tablet} {
    display: flex;
    flex-direction: column;
    justify-items: center;
    /* background-color: red; */
  }
`;
