import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
    style={{
      enableBackground: "new 0 0 496 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M495.8 99c-6.9 72.2-36.6 247.5-51.8 328.4-6.4 34.2-19 45.7-31.2 46.8-26.5 2.4-46.7-17.5-72.3-34.4-40.2-26.3-62.9-42.7-101.9-68.5-45.1-29.7-15.9-46 9.8-72.7 6.7-7 123.6-113.3 125.9-122.9.3-1.2.6-5.7-2.1-8.1-2.7-2.4-6.6-1.6-9.4-.9-4 .9-68.3 43.4-192.7 127.3-18.2 12.5-34.7 18.6-49.5 18.3-16.3-.3-47.7-9.2-71-16.8-28.6-9.3-51.3-14.2-49.4-30 1-8.2 12.4-16.6 34-25.2 133.1-58 221.9-96.3 266.3-114.7 126.9-52.8 153.2-61.9 170.4-62.2 3.8-.1 12.2.9 17.7 5.3 3.6 3.2 5.9 7.6 6.5 12.4.9 5.9 1.1 11.9.7 17.9z" />
  </svg>
);

export default SvgComponent;
