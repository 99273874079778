import React from "react";
import styled from "styled-components";
import SectionWrapper from "../Reusables/SectionWrapper";
import SectionTitle from "../Reusables/SectionTitle";
import SectionSkills from "../Reusables/SectionSkills";

const languages = [
  { skill: "English", level: "Fluent" },
  { skill: "Portuguese", level: "Native" },
  { skill: "Spanish", level: "Basic" },
];

export default function Languages() {
  return (
    <SectionWrapper id="Languages" oneCol={true}>
      <SectionTitle>Languages</SectionTitle>
      <SectionSkills skills={languages}></SectionSkills>
    </SectionWrapper>
  );
}
