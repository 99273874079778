import React from "react";
import styled from "styled-components";
import SocialIcons from "../Header/SocialIcons";
import BackgroundFooter from "./BackgroundFooter";
import Name from "./Name";
import Device from "../../Defaults/Device";

export default function Footer() {
  return (
    <Wrapper>
      <Name></Name>
      <SocialIcons></SocialIcons>

      <BackgroundFooter></BackgroundFooter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 13rem;
  width: 100%;
  padding: 1.7rem 2.5rem;
  z-index: 10;

  display: flex;
  flex-direction: row;

  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  p {
    font-family: "lato", sans-serif;
  }
  @media ${Device.mobile} {
    justify-content: center;
  }
`;
