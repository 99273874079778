export const Color = {
  white: "#f8f8f8",
  main200: "#E4F3F7",
  main300: "#cee8ec",
  main600: "#388C9F",
  main900: "#023341",

  grey600: "#706F6E",
  grey800: "#363636",

  hover: "#ffffff26",

  black: "#000000",
};
