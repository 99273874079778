import React from "react";
import styled from "styled-components";
import ProfilePic from "../../Images/Profile.jpeg";
import Device from "../../Defaults/Device";

export default function Profile({ screenScrolled }) {
  return (
    <Wrapper>
      {/* <div className={`profileDiv ${screenScrolled ? "screenScrolled" : ""}`}>
        <img className="profile" src={ProfilePic} alt="Ulysses Pinheiro"></img>
      </div> */}
      <div className={`name ${screenScrolled ? "screenScrolled" : ""}`}>
        <h2 className={`${screenScrolled ? "screenScrolled" : ""}`}>
          Ulysses {screenScrolled ? <></> : <br />} Pinheiro
        </h2>
        <p>Web Developer</p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .profile {
    line-height: 0;
    border-radius: 100rem;
    border: 1px solid black;
    width: 100%;
  }

  .profileDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    padding: 0.5rem;
    width: 11rem;

    background-color: white;
    border-radius: 100rem;

    @media ${Device.laptop} {
      width: 8rem;
      height: 8rem;
      padding: 0.3rem;

      &.screenScrolled {
        width: 4rem;
        height: 4rem;
        padding: 0.15rem;
      }
    }

    @media ${Device.mobile} {
      width: 6rem;
      height: 6rem;
      padding: 0.25rem;
    }

    transition: all 300ms;
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-family: "Unna", serif;
      font-weight: bold;
      font-size: 3rem;

      @media ${Device.laptop} {
        font-size: 2.5rem;
      }

      /* REMOVED DUE TO REMOVING PROFILE PICTURE */

      @media ${Device.mobile} {
        font-size: 2.4rem;

        &.screenScrolled {
          font-size: 2rem;
        }
      }
    }

    /* REMOVED DUE TO REMOVING PROFILE PICTURE */
    @media ${Device.laptop} {
      /* margin-left: 2rem; */
      margin-left: 0.5rem;
      &.screenScrolled {
        /* margin-left: 1rem; */
      }
    }

    @media ${Device.mobile} {
      /* margin-left: 1rem; */
      margin-left: 0rem;
    }
  }

  @media ${Device.laptop} {
    display: flex;
    height: fit-content;
    font-size: 0.8rem;
  }

  @media ${Device.mobile} {
    font-size: 0.7rem;
  }
`;
