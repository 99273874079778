import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
    style={{
      enableBackground: "new 0 0 496 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M270.3 256 496 481.7 473.7 504 248 278.3 22.3 504 0 481.7 225.7 256 0 30.3 22.3 8 248 233.7 473.7 8 496 30.3z" />
  </svg>
);

export default SvgComponent;
