import React from "react";
import { Color } from "../../Defaults/Color";
import styled from "styled-components";
import { Font } from "../../Defaults/Font";
import Device from "../../Defaults/Device";

export default function SectionTitle({ children }) {
  return (
    <CenterDiv>
      <Wrapper>
        <h2 className="title">{children}</h2>
        <div className="rectangle"></div>
      </Wrapper>
    </CenterDiv>
  );
}

const CenterDiv = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  color: ${Color.main600};
  ${Font.title};
  font-size: 1.5rem;
  position: relative;
  align-self: center;

  .title {
    margin-left: 1rem;

    @media ${Device.mobile} {
      font-size: 2rem;
    }
  }

  .rectangle {
    z-index: -1;
    position: absolute;
    top: 1.2rem;
    background-color: ${Color.main200};
    width: 7.5rem;
    height: 1.6rem;
  }
`;
