import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: { menu: false },
  reducers: {
    showMenu: (state) => {
      state.menu = true;
    },
    hideMenul: (state) => {
      state.menu = false;
    },
  },
});

export const { showMenu, hideMenul } = menuSlice.actions;

export default menuSlice;
