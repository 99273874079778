import React from "react";
import { Font } from "../../Defaults/Font";
import styled from "styled-components";
import { Color } from "../../Defaults/Color";

export default function SectionText({ children, className }) {
  return <Text className={className}>{children}</Text>;
}

const Text = styled.p`
  ${Font.text};
  color: ${Color.grey600};
  line-height: 1.5rem;

  &.date {
    color: ${Color.main600};
  }
`;
