import React from "react";
import styled from "styled-components";
import { Color } from "../../Defaults/Color";

export default function ShareOption({
  color,
  gradient,
  name,
  children,
  href,
  dataAction,
  target = "_self",
}) {
  return (
    <Wrapper href={href} target={target} data-action={dataAction}>
      <Icon
        style={color ? { backgroundColor: color } : { background: gradient }}
      >
        {children}
      </Icon>
      <p>{name}</p>
    </Wrapper>
  );
}

const Wrapper = styled.a`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Color.black};
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    background-color: ${Color.main200};
  }

  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
`;

const Icon = styled.div`
  background-color: white;
  color: black;
  border-radius: 50rem;
  padding: 1rem;
  height: 4rem;
  width: 4rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;
