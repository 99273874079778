import React from "react";
import styled from "styled-components";
import { Color } from "../../Defaults/Color";
import SectionComponent from "./SectionComponent";
import Device from "../../Defaults/Device";

export default function SectionSkills({ skills, languages }) {
  return (
    <Wrapper className={languages ? "languages" : ""}>
      {skills.map((e) => (
        <div className="WrapperSkill">
          <SectionComponent
            title={e.skill}
            subtitle1={e.level}
          ></SectionComponent>
          <div className="SkillRectangle"></div>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  gap: 1rem 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media ${Device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  &.languages {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .WrapperSkill {
    position: relative;
  }

  .SkillRectangle {
    position: absolute;
    top: 1rem;
    left: -0.5rem;
    width: 1.6rem;
    height: 3.1rem;
    background-color: ${Color.main200};
    z-index: -1;
  }
`;
