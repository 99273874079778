import { useState } from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import styled from "styled-components";
import MainContent from "./Components/MainContent/MainContent";
import Footer from "./Components/Footer/Footer";
import Device from "./Defaults/Device";
import useWindowDimensions from "./Hooks/useWindowDimensions";
import Share from "./Components/Share/Share";
import ModalBackground from "./Components/Reusables/ModalBackground";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

function App() {
  const { height, width } = useWindowDimensions();
  const modal = useSelector((state) => state.modal.modal);

  return (
    <div className="App">
      <GridLayout>
        <Header></Header>
        <MainContent></MainContent>
        {width < Device.laptopNum ? <Footer></Footer> : ""}
        <AnimatePresence>
          {modal ? (
            <>
              <motion.div
                transition={{ ease: "easeOut", duration: 0.4 }}
                initial={{
                  opacity: 0,
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  zIndex: 200,
                }}
                animate={{
                  opacity: 1,
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  zIndex: 200,
                }}
                exit={{
                  opacity: 0,
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  zIndex: 200,
                }}
              >
                <ModalBackground></ModalBackground>
              </motion.div>
              <motion.div
                transition={{ ease: "easeOut", duration: 0.2 }}
                initial={{ opacity: 0, zIndex: 200 }}
                animate={{ opacity: 1, zIndex: 200 }}
                exit={{ opacity: 0, zIndex: 200 }}
              >
                <Share></Share>
              </motion.div>
            </>
          ) : (
            ""
          )}
        </AnimatePresence>
      </GridLayout>
    </div>
  );
}

export default App;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  height: 100vh;
  justify-items: center;

  @media ${Device.laptop} {
    grid-template-columns: 1fr;
  }
`;
